<template>
  <main-layout>
    <div class="main">
      <!-- main-header -->
      <div class="main__header header">
        <div class="header__wysiwyg wysiwyg">
          <h2>{{company.view_company_profile.companyname}}</h2>
        </div>
      </div>

      <div class="main__content">
        <div class="company">
          <Banner v-if="renderComponent" ref="banner" :type="'view'"></Banner>
          <TeamMembers v-if="renderComponent" :type="'view'"></TeamMembers>
          <Description v-if="renderComponent" ref="description" :type="'view'"></Description>
          <Media v-if="renderComponent" :type="'view'"></Media>
        </div>
      </div>

  </div>
  <ViewfilePopup></ViewfilePopup>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main.vue'
import store from '../store'
import Banner from '@/components/company/Banner'
import TeamMembers from '@/components/company/TeamMembers'
import Description from '@/components/company/Description'
import Media from '@/components/company/Media'
import ViewfilePopup from '@/components/popups/Viewfile'

export default {
    name: "CompanyProfile",
    components: {
        MainLayout,
        Banner,
        TeamMembers,
        Description,
        Media,
        ViewfilePopup,
    },
    data() {
        return {
          renderComponent: true
        }
    },


    methods: {
      getCompanyInfo(id) {
        store.dispatch('GET_COMPANY_INFO', id);
      },
    },
    created() {
        this.getCompanyInfo(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
        this.getCompanyInfo(to.params.id);
        next();
    },


    computed: {
      company: () => store.state.company,
      userdata: () => store.state.user,
      popups: () => store.state.popups,
    }
}
</script>
